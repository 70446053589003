import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import { Pagination } from "swiper"
import CardOpinion from '../cards/CardOpinion'

const SwiperOpiniones = () => {
    return (
        <Swiper
            style={{
                "--swiper-pagination-color": "#ADADAD",
                "--swiper-pagination-bullet-inactive-color": "#696969",
                "--swiper-pagination-bullet-inactive-opacity": "1",
                "--swiper-pagination-bullet-size": "16px",
                "--swiper-pagination-bullet-horizontal-gap": "6px"
            }}
            pagination={{
                dynamicBullets: true,
            }}
            spaceBetween={24}
            modules={[Pagination]}
            className="mySwiper">
            <SwiperSlide>
                <CardOpinion
                    text="“Vértices nos viene apoyando en la organización de eventos y gestionando recursos para nuestras actividades.”"
                    autor="CARLOS, Gestor cultural"
                    lugar="LIMA, PERÚ"
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardOpinion
                    text="“Gracias a estos murales nuestro barrio está más bonito y bien cuidado, esperemos que vuelvan pronto.”"
                    autor="DON VICTOR, Vecino de SJL"
                    lugar="LIMA, PERÚ"
                />
            </SwiperSlide>
            <SwiperSlide>
                <CardOpinion
                    text="“Ha sido una buena experiencia participar con Vértices, gracias por apoyarnos en nuestro evento.”"
                    autor="MARTÍN, Astista urbano"
                    lugar="GUAYAQUIL, ECUADOR"
                />
            </SwiperSlide>
        </Swiper>
    )
}

export default SwiperOpiniones