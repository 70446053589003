import { useEffect, useState } from "react";
import Splash from "./components/Splash";
import { AnimatePresence } from "framer-motion";
import { Route, Routes } from "react-router-dom";
import Main from "./components/pages/Main";
import B2B from "./components/pages/B2B";
import ScrollToTop from "./hooks/ScrollToTop";
import axios from "axios";

function App() {
  const [splash, setSplash] = useState({state:true, data: null})

  useEffect(() => {
    axios.get("https://www.api.vertices.pro/wp-json/wp/v2/pages/5")
      .then( (res) => {
        setSplash({state: false, data: res.data.acf})
      } )
      .catch()
  }, [])


  return (
    <AnimatePresence>
      {
        splash.state ?
          <Splash />
          :
          <>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Main data={splash.data} />} />
              <Route path="/organizaciones" element={<B2B />} />
            </Routes>
          </>
      }
    </AnimatePresence>
  );
}

export default App;
