const CardPropuesta = (props) => {
  return (
    <div className="text-negroV">
      <img
        src={props.img}
        alt={props.title}
        className="w-full">
      </img>
      <p
        className="font-[Jost] text-center text-[14px] 
          font-medium py-[20px] pb-[4px] leading-[20px]
          sm:font-semibold sm:pt-[13px] sm:leading-[20px] sm:pb-[23px]">
        {props.title}
      </p>
      <p
        className="font-Nunito font-light text-center
          text-[12px] leading-[16px] sm:sm:leading-[25px] sm:text-[18px]">
        <del className="">
          {props.text}
        </del>
      </p>
    </div>
  )
}

export default CardPropuesta
