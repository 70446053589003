import { useState } from "react"
import BtnCotiza from "./buttons/BtnCotiza"
import BtnSiguenos from "./buttons/BtnSiguenos"
import CardPersonaliza from "./cards/CardPersonaliza"
import CardValores from "./cards/CardValores"
import './styles/styleAux.css'
import Eventos from "./itemTab/Eventos"
import Mural from "./itemTab/Mural"
import Productos from "./itemTab/Productos"
import BtnSumate from "./buttons/BtnSumate"
import { Link } from "react-router-dom"
import { linkB2C } from "../global/var"

const Home = (props) => {
   const data = props.data
   const [active, setActive] = useState("Eventos")

   return (
      <div
         className="">
         <section id="Hero"
            className=" z-0 bg-cover bg-center mt-[64px]
            bg-[url('/public/imagenes/bg-hero.jpg')] relative
            before:heroBefore">
            <div
               className="w-[316px] mx-auto pt-[172px] pb-[72px]
               sm:w-full sm:px-[8%] sm:pt-[325px] sm:pb-[65px]
               md:w-[655px] md:px-0">
               <h1>
                  <span className="font-black">Creamos murales </span>
                  en cualquier ambiente
               </h1>
               <p
                  className="font-Nunito font-bold text-center text-white
                  pt-[24px] pb-[40px] text-[16px]
                  sm:pt-[20px] sm:pb-[54px] sm:text-[24px]">
                  {data.hero.p}
               </p>
               <div className="flex justify-center">
                  <a
                     href={linkB2C}
                     target="_blank">
                     <BtnCotiza bg="#FFDC4D" color="#232323" />
                  </a>
               </div>
            </div>
         </section>

         <section id="Section Credits"
            className="bg-negroV py-[24px] sm:py-[36px]">
            <div
               className="flex justify-center items-center
               gap-[32px] sm:gap-[40px] md:gap-[64px] lg:gap-[100px]">
               <img src="/svg/credit1.svg" alt="" className="w-[80px] sm:w-[175px]" />
               <img src="/svg/credit2.svg" alt="" className="w-[72px] sm:w-[200px]" />
               <img src="/svg/credit3.svg" alt="" className="w-[72px] sm:w-[164px]" />
            </div>
         </section>

         <section id="Murales">
            <div
               className="mx-auto w-[312px] pt-[48px] z-10
               sm:w-full sm:px-[8%] sm:pt-[64px]
               xl:w-[981px] xl:px-0">
               <h2 className="text-rosaV">Murales Personalizados</h2>
               <div
                  className="grid gap-[24px] py-[40px] 
                  sm:py-[52px] sm:gap-[52px]
                  lg:grid-cols-[1fr_236px] lg:gap-[150px]">
                  <img
                     src="/imagenes/logoVertices.png"
                     alt="Logo Vertices"
                     className="mx-auto w-[144px] sm:w-[236px] lg:order-2">
                  </img>
                  <div className="grid gap-[16px] sm:gap-[36px] lg:order-1">
                     <p className="textP">
                        Este mundo necesita de más color, somos <span className="font-bold">VÉRTICES</span> {" "}
                        y juntos podemos lograr el cambio. Recuperar espacios públicos a través del graffiti
                        y el arte urbano en diversas zonas de Lima es parte de nuestro día a día.
                     </p>
                     <p className="textP">
                        Y ahora desde esta plataforma ofrecemos murales personalizados que pueden decorar, acompañar
                        y embellecer tu hogar, oficina o espacio favorito. Además creamos productos artísticos a tu medida.
                     </p>
                     <p className="textP">
                        Puedes {" "}
                        <a
                           href={linkB2C}
                           target="_blank">
                           <span className="font-bold text-rosaV underline cursor-pointer">
                              cotizar tu mural aquí
                           </span>
                        </a>
                     </p>
                  </div>
               </div>
               <img
                  src="/imagenes/img-murales.jpg"
                  alt="Mural"
                  className="mx-auto rounded-t-[40px]
                  w-full lg:w-[865.68px]">
               </img>
            </div>
         </section>

         <section id="Personaliza"
            className="h-auto z-0 bg-cover bg-center
            bg-[url('/public/imagenes/bg-mural.jpg')]">
            <div
               className="w-[312px] py-[40px] mx-auto
               sm:w-full sm:py-[64px] sm:px-[8%]
               xl:w-[980px] xl:px-0">
               <h2 className="text-white">Personaliza tu mural</h2>
               <div
                  className="grid mx-auto gap-[48px] py-[48px]
                  sm:grid-cols-2 sm:gap-[54px] sm:py-[52px]
                  lg:grid-cols-3">
                  <CardPersonaliza bg="#7BF8BF" color="#232323" number="1"
                     sub="Selecciona tu diseño"
                     text="Compártenos el diseño que tienes en mente o nosotros podemos crearlo para ti." />
                  <CardPersonaliza bg="#FFDC4D" color="#232323" number="2"
                     sub="Elige las dimensiones"
                     text="Cuéntanos sobre el espacio, tipo de pared y medidas de tu futuro mural para cotizarlo." />
                  <CardPersonaliza bg="#E92F72" color="#FFF" number="3"
                     sub="Materiales y cotización"
                     text="Finalmente definamos lugar, fecha, materiales y detalles finales."
                     a="Cotiza aquí"/>
               </div>
               <div className="flex justify-center">
                  <a
                     href={linkB2C}
                     target="_blank">
                     <BtnCotiza bg="#E92F72" color="#FFF" />
                  </a>
               </div>
            </div>
         </section>

         <section id="Proyectos">
            <div
               className="w-[280px] py-[48px] mx-auto
               sm:w-full sm:px-[8%] sm:py-[64px]
               xl:w-[1168px] xl:px-0">
               <h2 className="text-rosaV">Nuestros Proyectos</h2>
               <p className="textP text-center
                  pt-[24px] pb-[48px] sm:pt-[28px] sm:pb-[52px]">
                  Puedes ver nuestros últimos trabajos
               </p>
               <div
                  className="grid gap-[56px] mx-auto
                  sm:grid-cols-2 lg:grid-cols-3">
                  <img src="/imagenes/proyectos/p1.jpg" alt=" " />
                  <img src="/imagenes/proyectos/p2.jpg" alt=" " />
                  <img src="/imagenes/proyectos/p3.jpg" alt=" " />
                  <img src="/imagenes/proyectos/p4.jpg" alt=" " />
                  <img src="/imagenes/proyectos/p5.jpg" alt=" " />
                  <img src="/imagenes/proyectos/p6.jpg" alt=" " />
               </div>
               <div className="flex justify-center pt-[56px]">
                  <a
                     href="https://www.instagram.com/vertices.pro/"
                     target="_blank">
                     <BtnSiguenos />
                  </a>
               </div>
            </div>
         </section>

         <section id="Section Valores"
            className="bg-negroV relative">
            <img
               src="/imagenes/bg-logo.png"
               alt="fondo"
               className="absolute right-[50%] translate-x-[50%]
               bottom-[50%] translate-y-[50%]
               scale-[0.65] sm:scale-125 md:scale-75 lg:scale-100"
            />
            <div
               className="w-[318px] py-[40px] mx-auto
               sm:w-full sm:py-[64px] sm:px-[8%]
               xl:w-[780px] xl:px-0 relative
               bg-cover bg-center">
               <h2 className="text-white ">Nuestros valores</h2>
               <div
                  className="grid mx-auto gap-[24px] pt-[88px] 
                  sm:grid-cols-2  
                  md:grid-cols-3 lg:gap-[156px] lg:pt-[144px]">
                  <CardValores
                     icon="/svg/icon1.svg"
                     color="#7BF8BF"
                     p="Unión"
                     mancha="/svg/mancha1.svg" />
                  <CardValores
                     icon="/svg/icon2.svg"
                     color="#FFDC4D"
                     p="Crecimiento"
                     mancha="/svg/mancha2.svg" />
                  <CardValores
                     icon="/svg/icon3.svg"
                     color="#E92F72"
                     p="Acompañamiento"
                     mancha="/svg/mancha3.svg" />
               </div>
            </div>
         </section>

         <section id="Opinion"
            className="bg-[url('/public/imagenes/home/bgColaborar.jpg')] relative bg-center z-10
            before:w-full before:h-full before:absolute before:top-0 before:bg-black/[0.7]
            before:!z-[-1] bg-no-repeat bg-cover
            py-[48px] sm:py-[72px]">
            <h2 className="text-white px-[10%] sm:px-0">COLABORA CON NOSOTROS </h2>
            <div
               className='text-white text-center z-10
                pb-[38px] w-[312px] mx-auto sm:w-auto sm:mx-[8%]
                md:mx-auto md:max-w-[621px]'>
               <p
                  className='font-[400] text-white block
                   text-[12px] leading-[24px] mt-[15px]
                   sm:text-[18px] sm:leading-[32px] '>
                  Si quieres auspiciar un proyecto o ser parte de
                  las organizaciones y empresas que nos apoyan, escríbenos o conoce más aquí
               </p>
               <div className='flex justify-center mt-[24px]'>
                  <Link
                     to="/organizaciones">
                     <BtnSumate bg="#FFDC4D" color="#232323" />
                  </Link>
               </div>
            </div>
         </section>

         <section id="Servicios"
            className="bg-white py-[64px] w-[321px] mx-auto sm:w-auto">
            <h2 className="text-[#232323]">Nuestros Servicios</h2>
            <div
               className="text-[20px] mt-[40px]
               grid grid-cols-3 
               sm:mx-[8%] md:flex md:justify-center md:mx-0">
               <div
                  style={active === "Eventos" ? StyleActiveTab : StyleDisabledTab}
                  onClick={() => setActive("Eventos")}
                  className="cursor-pointer pb-[6px] text-center
                  text-[16px] leading-[21.82px]
                  sm:text-[20px] sm:leading-[27px] sm:pb-[11px] 
                  md:mx-[50px] md:px-[30px] 
                  hover:!text-[#E92F72] ">
                  Eventos
               </div>
               <div
                  style={active === "Mural" ? StyleActiveTab : StyleDisabledTab}
                  onClick={() => setActive("Mural")}
                  className="cursor-pointer pb-[6px] text-center
                  text-[16px] leading-[21.82px]
                  sm:text-[20px] sm:leading-[27px] sm:pb-[11px] 
                  md:mx-[50px] md:px-[30px] 
                  hover:!text-[#E92F72]">
                  Mural en vivo
               </div>
               <div
                  style={active === "Productos" ? StyleActiveTab : StyleDisabledTab}
                  onClick={() => setActive("Productos")}
                  className="cursor-pointer pb-[6px] text-center
                  text-[16px] leading-[21.82px]
                  sm:text-[20px] sm:leading-[27px] sm:pb-[11px] 
                  md:mx-[50px] md:px-[30px] 
                  hover:!text-[#E92F72]">
                  Productos
               </div>
            </div>

            {
               active === "Eventos" ?
                  <Eventos />
                  :
                  active === "Mural" ?
                     <Mural />
                     :
                     <Productos />
            }

         </section>
      </div>
   )
}

const StyleActiveTab = {
   fontWeight: "700",
   color: "#E92F72",
   borderBottom: "3px solid"
}

const StyleDisabledTab = {
   fontWeight: "400",
   color: "#232323",
}

export default Home