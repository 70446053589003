import BtnContactanos from "./buttons/BtnContactanos"
import { useMediaQuery } from 'react-responsive'
import { useState } from "react"
import { Link } from "react-scroll"
import { AnimatePresence, motion } from "framer-motion"
import { linkB2C } from "../global/var"

const NavBar = () => {

   const isLg = useMediaQuery({ query: '(min-width: 1110px)' })
   const [menu, setMenu] = useState(false)

   return (
      <header
         className="flex justify-items-center justify-center bg-negroV z-20
         w-full py-[12px] h-[64px] sm:py-[16px] sm:h-[80px] fixed top-0
         shadow-negroV shadow-sm">
         <nav
            className="flex justify-between
            w-[312px]
            sm:w-full sm:px-[8%] 
            xl:w-[1180px] xl:px-0">
            <Link
               to="Hero"
               spy={true}
               offset={-64}
               smooth={true}
               duration={500}
               className="cursor-pointer">
               <img
                  src="/svg/logo.svg"
                  alt="Logo"
                  className="w-[144px] sm:w-auto"
               />
            </Link>
            {isLg ?
               <div className="flex items-center gap-[80px] scale-75  lgAux:scale-100">
                  <div className="flex gap-[52px] xl:gap-[64px]">
                     <Link
                        to="Murales"
                        spy={true}
                        offset={-64}
                        smooth={true}
                        duration={500}>
                        <p className="pNav">Murales personalizados</p>
                     </Link>
                     <Link
                        to="Proyectos"
                        spy={true}
                        smooth={true}
                        offset={-64}
                        duration={650}>
                        <p className="pNav">Proyectos</p>
                     </Link>
                     <Link
                        to="Servicios"
                        spy={true}
                        smooth={true}
                        offset={-64}
                        duration={800}>
                        <p className="pNav">Servicios</p>
                     </Link>
                  </div>
                  <a
                     href="https://api.whatsapp.com/send?phone=+51961430490&text=Hola,%20somos%20V%C3%A9rtices.%20%C2%BFCu%C3%A9ntanos%20c%C3%B3mo%20podemos%20ayudarte"
                     target="_blank">
                     <BtnContactanos />
                  </a>
               </div>
               :
               <img 
                  src="/svg/burguer.svg"
                  alt="burger"
                  className="cursor-pointer"
                  onClick={() => setMenu(!menu)}>
               </img>
            }
         </nav>

         <AnimatePresence>
            {menu && !isLg &&
               <motion.div
                  key="navbar movil"
                  initial={{ opacity: 0, x: 150 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                  exit={{ opacity: 0, x: 150 }}
                  className="bg-negro absolute right-0 z-20
                   w-full px-[24px] top-[64px] bg-negroV
                   sm:top-[80px]">
                  <Link
                     to="Murales"
                     spy={true}
                     offset={-64}
                     smooth={true}
                     duration={800}
                     onClick={() => setMenu(!menu)}>
                     <div className="pNav text-center py-[24px]">
                        Murales personalizados
                     </div>
                  </Link>
                  <Link
                     to="Proyectos"
                     spy={true}
                     smooth={true}
                     offset={-64}
                     duration={800}
                     onClick={() => setMenu(!menu)}>
                     <div
                        className="pNav py-[24px] text-center border-y-[1px] border-white">
                        Proyectos
                     </div>
                  </Link>
                  <Link
                     to="Servicios"
                     spy={true}
                     smooth={true}
                     offset={-64}
                     duration={800}
                     onClick={() => setMenu(!menu)}>
                     <div className="pNav py-[24px] text-center border-b-[1px] border-white">
                        Servicios
                     </div>
                  </Link>
                  <div className="flex  justify-center py-[24px]">
                     <a
                        href={linkB2C}
                        target="_blank">
                        <BtnContactanos />
                     </a>
                  </div>
               </motion.div>
            }
         </AnimatePresence>
      </header>
   )
}

export default NavBar
