import './styles/styleAux.css'

const FooterB2B = () => {

  return (
    <footer
      className="bg-negroV">
      <div className='mx-auto
          w-[312px] py-[28px]
          md:w-full sm:py-[32px] '>
        <p 
          className='font-[Jost] font-medium text-center text-white'>
          © Copyright 2022 VÉRTICES | All Rights Reserved | Powered by
          <a
            href="https://www.ciclos.studio/"
            target="_blank">
            <span className=' font-bold text-verdeV'> CICLOS STUDIO </span>
          </a>
        </p>
      </div>
    </footer>
  )
}

export default FooterB2B