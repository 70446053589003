import React from 'react'
import Footer from '../Footer'
import Home from '../Home'
import NavBar from '../NavBar'
import WspHome from '../wsp/WspHome'
import { motion } from 'framer-motion'

const Main = (props) => {
    return (
        <motion.div
            key="splash"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}>
            <NavBar />
            <Home data={props.data} />
            <Footer />
            <WspHome />
        </motion.div>
    )
}

export default Main