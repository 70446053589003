import React from 'react'
import { useForm } from 'react-hook-form'
import emailjs from "@emailjs/browser"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'
import { useLocation } from 'react-router-dom';


const FormContactanos = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm()

    const errorStyle = {
        color: "rgba(214, 10, 17, 0.64)",
        fontSize: "16px",
        marginLeft: "8px"
    }

    const onSubmit = (data, e) => {
        const id = toast.loading("Please wait...")
        emailjs.sendForm('service_an0md7w', 'template_yujfjxq', e.target, '4lKk6B1u5GaMLbqA0')
            .then(res => {
                toast.update(id,
                    {
                        render: "Información enviada",
                        type: "success",
                        autoClose: 4000,
                        isLoading: false,
                        draggable: true
                    })
                reset()
            })
            .catch(error => toast.update(id, {
                render: "Ocurrio un error",
                type: "Error",
                autoClose: 4000,
                isLoading: false,
                draggable: true
            }))
    }

    const inputErrorStyle = {
        boxShadow: "0px 2px 8px rgba(214, 17, 17, 0.64)",
        borderStyle: "none"
    }


    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid mx-auto gap-[15px]
                  w-[312px] py-[32px] pb-[72px]
                  sm:w-[500px] sm:gap-[18px]
                  lg:w-[328px] lg:py-[72px] lg:gap-[15px]
                  xl:ml-[196px]">
            <div>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </div>
            <h2 className="text-rosaV"> CONTÁCTANOS </h2>
            <p className='text-center py-[8px] pt-0'>Ingresa tus datos para contactarte</p>
            <div>
                <input
                    style={errors.name && inputErrorStyle}
                    id='name'
                    type="text"
                    placeholder="Ingresa tu nombre"
                    {...register("name",
                        { required: true })}>
                </input>
                {
                    errors.name && <span style={errorStyle}>Este campo es requerido</span>
                }
            </div>
            <div>
                <input
                    style={errors.empresa && inputErrorStyle}
                    id='empresa'
                    type="text"
                    placeholder="empresa u organización"
                    {...register("empresa",
                        { required: true })}>
                </input>
                {
                    errors.empresa && <span style={errorStyle}>Este campo es requerido</span>
                }
            </div>
            <div>
                <input
                    style={errors.telefono && inputErrorStyle}
                    id='telefono'
                    type="text"
                    placeholder="Teléfono"
                    className=""
                    {...register("telefono",
                        { required: true })}>
                </input>
                {
                    errors.telefono && <span style={errorStyle}>Este campo es requerido</span>
                }
            </div>
            <div>
                <input
                    style={errors.email && inputErrorStyle}
                    id='email'
                    type="text"
                    placeholder="Email"
                    {...register("email",
                        { required: true })}>
                </input>
                {
                    errors.email && <span style={errorStyle}>Este campo es requerido</span>
                }
            </div>
            <div>
                <textarea
                    style={errors.mensaje && inputErrorStyle}
                    id='mensaje'
                    type="textarea"
                    placeholder="Cuéntanos"
                    className="h-[120px] sm:h-[84px]"
                    {...register("mensaje",
                        { required: true })}>
                </textarea>
                {
                    errors.mensaje && <span style={errorStyle}>Este campo es requerido</span>
                }
            </div>
            <input
                type="submit"
                className="flex items-center justify-center cursor-pointer
                font-[700] text-[Jost] text-[18px]  w-full h-[48px] bg-rosaV text-white">
            </input>
        </form>
    )
}

export default FormContactanos