const BtnContactanos = () => {
  return (
    <button
        className="bg-[#E92F72] text-center fkex items-center
        w-[175px] py-[8px] rounded-[8px] hover:bg-[#E92F72]/90">
        <p className="textbutton text-white">
          CONTÁCTANOS
        </p>
    </button>
  )
}

export default BtnContactanos
