import React from 'react'

const Logos = () => {
    return (
        <div
            className="w-[312px] mx-auto gap-y-[40px]
             grid  justify-items-center mt-[48px] items-center grid-cols-2
             sm:max-w-[1139px] sm:w-auto sm:grid-cols-2  sm:px-[2%] sm:scale-100 
             md:grid-cols-3 md:gap-y-[60px] md:mt-[78px]
             lg:grid-cols-4 xl:px-0">
            <img
                src="/imagenes/homeB2B/organizaciones/logo_viaCodigo.png"
                alt="Primera iniciativa"
                className=" scale-75 sm:scale-100 "
            />
            <img
                src="/imagenes/homeB2B/organizaciones/logo_recidar.png"
                alt="Primera iniciativa"
                className="scale-75 sm:scale-100"
            />
            <img
                src="/imagenes/homeB2B/organizaciones/logo_adp.png"
                alt="Primera iniciativa"
                className="scale-75 sm:scale-100"
            />
            <img
                src="/imagenes/homeB2B/organizaciones/logo_ciclosStudio.png"
                alt="Primera iniciativa"
                className="scale-75 sm:scale-100"
            />
            <img
                src="/imagenes/homeB2B/organizaciones/logo_meetingOfStyles.png"
                alt="Primera iniciativa"
                className="scale-75 sm:scale-100"
            />
            <img
                src="/imagenes/homeB2B/organizaciones/logo_mural.png"
                alt="Primera iniciativa"
                className="scale-75 sm:scale-100"
            />
            <img
                src="/imagenes/homeB2B/organizaciones/logo_roadPaint.png"
                alt="Primera iniciativa"
                className="scale-75 sm:scale-100"
            />
            <img
                src="/imagenes/homeB2B/organizaciones/logo_somosLa30.png"
                alt="Primera iniciativa"
                className="scale-75 sm:scale-100"
            />
        </div>
    )
}

export default Logos