import React from 'react'
import { linkB2B } from '../../global/var'

const WspB2B = () => {
    return (
        <div
            className="fixed bottom-[3%] right-[3%] z-10">
            <a
                href={linkB2B}
                target="_blank">
                <img
                    src="/BotonflotanteWsp.png"
                    alt="Icon whatsapt"
                    className=" hover:scale-110 transition-all ease-out"
                />
            </a>
        </div>
    )
}

export default WspB2B