const BtnCotiza = (props) => {
  return (
    <button
      style={{background: props.bg, color: props.color}}
      className="flex items-center justify-center hover:opacity-[0.9]
        w-[240px] h-[56px] sm:w-[244px] sm:h-[52px] rounded-[8px]">
      <p className="font-[Jost] font-bold
        text-[16px] sm:text-[18px]">
        COTIZA TU MURAL
      </p>
    </button>
  )
}

export default BtnCotiza
