import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import { Pagination } from "swiper"

const SwiperHeroB2B = () => {
    return (
        <div
            className='SliderWrapper'>
            <Swiper
                style={{
                    "--swiper-pagination-color": "#FFDC4D",
                    "--swiper-pagination-bullet-inactive-color": "#B1B1B1",
                    "--swiper-pagination-bullet-inactive-opacity": "1",
                    "--swiper-pagination-bullet-size": "16px",
                    "--swiper-pagination-bullet-horizontal-gap": "6px"
                }}
                pagination={{
                    dynamicBullets: true,
                }}
                spaceBetween={24}
                modules={[Pagination]}
                className="mySwiper w-[312px]  sm:w-[480px] lg:w-[459px]">
                <SwiperSlide>
                    <img
                        src="/imagenes/homeB2B/hero/hero1.png"
                        alt="image slider"
                        className="pb-[35px] sm:w-[500px] lg:w-[459px]"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src="/imagenes/homeB2B/hero/hero2.png"
                        alt="image slider"
                        className="pb-[35px] sm:w-[500px] lg:w-[459px]"
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src="/imagenes/homeB2B/hero/hero3.png"
                        alt="image slider"
                        className="pb-[35px] sm:w-[500px] lg:w-[459px]"
                    />
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default SwiperHeroB2B