import React from 'react'
import { motion } from 'framer-motion'

const Splash = () => {
    return (
        <div
            className='bg-negroV h-[100vh] w-[100vw] flex justify-center items-center'>
            <motion.img
                key="splash"
                initial={{ opacity: 0}}
                animate={{ opacity: 1}}
                transition={{ duration: 2.5, scale:50 }}
                src="/svg/logo.svg"
                alt="Logo"
                className='scale-[1.3] md:scale-[1.5] lg:scale-[1.8] xl:scale-[1.9]'
            />
        </div>
    )
}

export default Splash