import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { AnimatePresence, motion } from 'framer-motion';
import { linkB2C } from '../../global/var';

const Mural = () => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="grid max-w-[980px] grid-cols-1  mt-[32px]
        justify-items-center
        sm:mx-[8%] md:mx-[8%]
        lgAux:grid-cols-2 lgAux:mt-[52px] lgAux:mx-auto ">
        <div
          className='order-2 mt-[20px]
          lgAux:!order-1 lgAux:mt-0'>
          <h3>EVENTOS Y FESTIVALES</h3>
          <p className="mt-[12px]">
            Amamos el color y el movimiento, participamos de eventos temáticos, pintura en vivo en festivales de música, actividades corporativas, 360, activaciones BTL y campañas publicitarias.
          </p>
          <p className="mt-[24px]">
            <a
              href={linkB2C}
              target="_blank">
              <span className="under">Déjanos un mensaje aquí</span>
            </a>
            {" "}y podrás participar de un descuento especial por fin de año.
          </p>

          <p className="mt-[24px]">
            También participamos de actividades con enfoque social y proBono, si eres una ONG o una Organización Civil,
            <a
              href={linkB2C}
              target="_blank">
              <span className="under"> {" "}escríbenos aquí  {" "}</span>
            </a>
            y cuéntanos sobre tu proyecto.
          </p>
        </div>
        <div
          className='lgAux:justify-self-end lgAux:order-2 mt-[24px] lg:mt-0'>
          <Swiper
            style={{
              "--swiper-pagination-color": "#ADADAD",
              "--swiper-pagination-bullet-inactive-color": "#696969",
              "--swiper-pagination-bullet-inactive-opacity": "1",
              "--swiper-pagination-bullet-size": "16px",
              "--swiper-pagination-bullet-horizontal-gap": "6px"
            }}
            pagination={{
              dynamicBullets: true,
            }}
            spaceBetween={24}
            modules={[Pagination]}
            className="mySwiper w-[312px]  sm:w-[450px] lgAux:w-[430px]">
            <SwiperSlide>
              <img
                src="/imagenes/home/services/Mural1.png"
                alt="image slider"
                className="pb-[35px]"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/imagenes/home/services/Mural2.png"
                alt="image slider"
                className="pb-[35px]"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src="/imagenes/home/services/Mural3.png"
                alt="image slider"
                className="pb-[35px]"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default Mural