import React from 'react'
import FooterB2B from '../FooterB2B'
import HomeB2B from '../HomeB2B'
import NavBarB2B from '../NavBarB2B'
import { motion } from 'framer-motion'
import WspB2B from '../wsp/WspB2B'

const B2B = () => {
    return (
        <motion.div
            key="splash"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}>
            <NavBarB2B />
            <HomeB2B />
            <FooterB2B />
            <WspB2B />
        </motion.div>
    )
}

export default B2B